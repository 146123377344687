import { useState, useEffect } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import {
  Store,
  LayoutDashboard,
  ClipboardList,
  UtensilsCrossed,
  Bell,
  Menu,
  X,
  LogOut,
} from "lucide-react";

function Layout() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  const navigation = [
    {
      name: "대시보드",
      path: "/",
      icon: LayoutDashboard,
    },
    {
      name: "주문관리",
      path: "/order",
      icon: ClipboardList,
    },
    {
      name: "메뉴관리",
      path: "/menus",
      icon: UtensilsCrossed,
    },
    {
      name: "스토어관리",
      path: "/store",
      icon: Store,
    },
  ];

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.error("로그아웃 실패:", error);
    }
  };

  // 화면 크기 변경 시 사이드바 자동 조절
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setSidebarOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="min-h-screen bg-gray-50">
      {/* 모바일 사이드바 오버레이 */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-50 z-20 md:hidden"
          onClick={() => setSidebarOpen(false)}
        />
      )}

      {/* 사이드바 */}
      <aside
        className={`
        fixed top-0 left-0 z-30 h-full w-64 bg-white shadow-lg transform transition-transform duration-200 ease-in-out
        ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } md:translate-x-0
      `}
      >
        {/* 로고 */}
        <div className="p-6">
          <div className="flex items-center space-x-3">
            <Store className="h-8 w-8 text-brand-600" />
            <div>
              <h1 className="text-xl font-bold text-gray-900">Smart Order</h1>
              <p className="text-sm text-gray-500">{user?.storeName}</p>
            </div>
          </div>
        </div>

        {/* 네비게이션 */}
        <nav className="mt-6 px-3">
          {navigation.map((item) => {
            const Icon = item.icon;
            const isActive = location.pathname === item.path;

            return (
              <Link
                key={item.path}
                to={item.path}
                className={`
                  flex items-center px-3 py-3 mb-1 text-sm font-medium rounded-lg
                  ${
                    isActive
                      ? "bg-brand-50 text-brand-600"
                      : "text-gray-700 hover:bg-gray-50"
                  }
                `}
              >
                <Icon className="h-5 w-5 mr-3" />
                <span>{item.name}</span>
                {item.badge > 0 && (
                  <span className="ml-auto bg-brand-100 text-brand-600 py-1 px-2 rounded-full text-xs">
                    {item.badge}
                  </span>
                )}
              </Link>
            );
          })}
        </nav>
      </aside>

      {/* 메인 컨텐츠 */}
      <div className="md:ml-64">
        {/* 헤더 */}
        <header className="bg-white shadow-sm">
          <div className="flex items-center justify-between px-4 py-3">
            <div className="flex items-center space-x-3">
              <button
                onClick={() => setSidebarOpen(!isSidebarOpen)}
                className="md:hidden text-gray-600 hover:text-gray-900"
              >
                {isSidebarOpen ? (
                  <X className="h-6 w-6" />
                ) : (
                  <Menu className="h-6 w-6" />
                )}
              </button>
              <h2 className="text-lg font-medium text-gray-900">
                {navigation.find((item) => item.path === location.pathname)
                  ?.name || "대시보드"}
              </h2>
            </div>

            <div className="flex items-center space-x-4">
              {/* 알림 버튼 */}
              <button className="relative p-2 text-gray-600 hover:text-gray-900 rounded-lg hover:bg-gray-100">
                <Bell className="h-5 w-5" />
                {notifications.length > 0 && (
                  <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                    {notifications.length}
                  </span>
                )}
              </button>

              {/* 로그아웃 버튼 */}
              <button
                onClick={handleLogout}
                className="flex items-center space-x-2 text-gray-600 hover:text-gray-900 p-2 rounded-lg hover:bg-gray-100"
              >
                <LogOut className="h-5 w-5" />
                <span className="hidden sm:inline">로그아웃</span>
              </button>
            </div>
          </div>
        </header>

        {/* 페이지 컨텐츠 */}
        <main className="p-6">
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export default Layout;
