import { useState, useEffect } from "react";
import { PlusCircle, Edit2, Trash2, AlertCircle, Tag } from "lucide-react";
import api from "../services/api";
import { formatKRW, formatNumberInput } from "../utils/formatters";
import { useToast } from "../hooks/useToast";
import { Toast } from "../components/Toast";

function MenuManagement() {
  const [menus, setMenus] = useState([]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedTag, setSelectedTag] = useState(null);
  const [isAddingTag, setIsAddingTag] = useState(false);
  const [newTagName, setNewTagName] = useState("");
  const [isAddingMenu, setIsAddingMenu] = useState(false);
  const [menuForm, setMenuForm] = useState({
    name: "",
    price: "",
    description: "",
    tagIds: [],
    image: null,
    imagePreview: null,
    is_sold_out: false,
  });
  const [isEditingMenu, setIsEditingMenu] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const { toast, showToast, hideToast } = useToast();

  useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = async () => {
    try {
      const [menusResponse, tagsResponse] = await Promise.all([
        api.get("/store/menus"),
        api.get("/store/tags", { params: { type: "menu" } }),
      ]);
      setMenus(menusResponse.data);
      setTags(tagsResponse.data);
    } catch (error) {
      setError("데이터를 불오는데 실패했습니다.");
      console.error("Failed to load data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddTag = async () => {
    try {
      if (!newTagName.trim()) return;

      const { data } = await api.post("/store/tags", {
        name: newTagName,
        type: "menu",
      });

      setTags([...tags, data]);
      setNewTagName("");
      setIsAddingTag(false);
    } catch (error) {
      setError("태그 추가에 실패했습니다.");
      console.error("Failed to add tag:", error);
    }
  };

  const handleTagClick = async (tag) => {
    try {
      setSelectedTag(tag);
      const { data } = await api.get(`/store/menus`, {
        params: { tagId: tag.id },
      });
      setMenus(data);
    } catch (error) {
      console.error("Failed to load menus by tag:", error);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setMenuForm((prev) => ({
        ...prev,
        image: file,
        imagePreview: URL.createObjectURL(file),
      }));
    }
  };

  const handleTagSelect = (tagId) => {
    setMenuForm((prev) => {
      const tagIds = prev.tagIds.includes(tagId)
        ? prev.tagIds.filter((id) => id !== tagId)
        : [...prev.tagIds, tagId];
      return { ...prev, tagIds };
    });
  };

  const handleAddMenu = async (e) => {
    e.preventDefault();

    if (!menuForm.tagIds.length) {
      showToast("태그를 하나 이상 선택해주세요.", "error");
      return;
    }

    try {
      setLoading(true);
      const { data: menu } = await api.post("/store/menus", {
        name: menuForm.name,
        price: parseInt(menuForm.price),
        description: menuForm.description,
        tagIds: menuForm.tagIds,
        status: menuForm.status || "active",
      });

      if (menuForm.image) {
        const formData = new FormData();
        formData.append("image", menuForm.image);
        await api.post(`/store/menus/${menu.id}/image`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      await loadInitialData();

      setMenuForm({
        name: "",
        price: "",
        description: "",
        tagIds: [],
        image: null,
        imagePreview: null,
        is_sold_out: false,
      });
      setIsAddingMenu(false);
      showToast("메뉴가 추가되었습니다.");
    } catch (error) {
      showToast("메뉴 추가에 실패했습니다.", "error");
      console.error("Failed to add menu:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (menu) => {
    setSelectedMenu(menu);
    setMenuForm({
      name: menu.name,
      price: menu.price.toString(),
      description: menu.description || "",
      tagIds: menu.tags.map((tag) => tag.id),
      image: null,
      imagePreview: menu.image_url
        ? `${process.env.REACT_APP_IMAGE_URL}/${menu.image_url}`
        : null,
      is_sold_out: menu.is_sold_out || false,
    });
    setIsEditingMenu(true);
  };

  const handleUpdateMenu = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      if (!menuForm.tagIds.length) {
        showToast("태그를 하나 이상 선택해주세요.", "error");
        return;
      }

      const updateData = {
        name: menuForm.name.trim(),
        price: parseInt(menuForm.price) || 0,
        description: menuForm.description?.trim() || null,
        tagIds: menuForm.tagIds || [],
        is_sold_out: menuForm.is_sold_out === true,
        status: "active",
      };

      await api.put(`/store/menus/${selectedMenu.id}`, updateData);

      if (menuForm.image) {
        const formData = new FormData();
        formData.append("image", menuForm.image);
        await api.post(`/store/menus/${selectedMenu.id}/image`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      await loadInitialData();
      setIsEditingMenu(false);
      setSelectedMenu(null);
      setMenuForm({
        name: "",
        price: "",
        description: "",
        tagIds: [],
        image: null,
        imagePreview: null,
        is_sold_out: false,
      });
      showToast("메뉴가 수정되었습니다.");
    } catch (error) {
      showToast("메뉴 수정에 실패했습니다.", "error");
      console.error("Failed to update menu:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteMenu = async (menuId) => {
    if (!window.confirm("정말 이 메뉴를 삭제하시겠습니까?")) {
      return;
    }

    try {
      setLoading(true);
      await api.delete(`/store/menus/${menuId}`);
      await loadInitialData();
      setError("");
    } catch (error) {
      setError("메뉴 삭제에 실패했습니다.");
      console.error("Failed to delete menu:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleSoldOut = async (menuId, isSoldOut) => {
    try {
      await api.patch(`/store/menus/${menuId}/toggle-sold-out`);
      const updatedMenus = menus.map((menu) => {
        if (menu.id === menuId) {
          return { ...menu, is_sold_out: !isSoldOut };
        }
        return menu;
      });
      setMenus(updatedMenus);
    } catch (error) {
      setError("품절 상태 변경에 실패했습니다.");
      console.error("Failed to toggle sold out:", error);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-600"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">메뉴 관리</h1>
        <div className="flex space-x-4">
          <button
            onClick={() => setIsAddingTag(true)}
            className="btn btn-secondary"
          >
            <Tag className="w-4 h-4 mr-2" />
            태그 추가
          </button>
          <button
            onClick={() => setIsAddingMenu(true)}
            className="btn btn-primary"
          >
            <PlusCircle className="w-4 h-4 mr-2" />
            메뉴 추가
          </button>
        </div>
      </div>

      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={hideToast}
        />
      )}

      {/* 태그 목록 */}
      <div className="mb-6">
        {isAddingTag ? (
          <div className="flex items-center space-x-2 mb-4">
            <input
              type="text"
              value={newTagName}
              onChange={(e) => setNewTagName(e.target.value)}
              className="input flex-1"
              placeholder="태그명"
            />
            <button onClick={handleAddTag} className="btn btn-primary">
              추가
            </button>
            <button
              onClick={() => setIsAddingTag(false)}
              className="btn btn-secondary"
            >
              취소
            </button>
          </div>
        ) : null}
        <div className="flex flex-wrap gap-2">
          <button
            onClick={() => {
              setSelectedTag(null);
              loadInitialData();
            }}
            className={`px-3 py-1 rounded-full text-sm border ${
              !selectedTag
                ? "bg-emerald-50 border-emerald-500 text-emerald-600"
                : "border-gray-300 text-gray-600 hover:bg-gray-50"
            }`}
          >
            전체
          </button>
          {tags.map((tag) => (
            <button
              key={tag.id}
              onClick={() => handleTagClick(tag)}
              className={`px-3 py-1 rounded-full text-sm border ${
                selectedTag?.id === tag.id
                  ? "bg-emerald-50 border-emerald-500 text-emerald-600"
                  : "border-gray-300 text-gray-600 hover:bg-gray-50"
              }`}
            >
              {tag.name}
            </button>
          ))}
        </div>
      </div>

      {/* 메뉴 목록 */}
      <div className="bg-white rounded-lg shadow">
        <div className="p-4">
          {menus.length === 0 ? (
            <div className="text-center py-8 text-gray-500">
              메뉴가 없습니다.
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {menus.map((menu) => (
                <div key={menu.id} className="bg-white rounded-lg shadow overflow-hidden">
                  <div className="relative">
                    <img
                      src={
                        menu.image_url
                          ? `${process.env.REACT_APP_IMAGE_URL}/${menu.image_url}`
                          : "/placeholder.png"
                      }
                      alt={menu.name}
                      className="w-full h-48 object-cover"
                    />
                    <button
                      onClick={() => handleToggleSoldOut(menu.id, menu.is_sold_out)}
                      className={`absolute top-2 right-2 text-sm px-3 py-1 rounded-full border transition-colors ${
                        menu.is_sold_out
                          ? "border-gray-300 bg-gray-50/90 text-gray-600 hover:bg-gray-100/90"
                          : "border-emerald-500 bg-emerald-50/90 text-emerald-600 hover:bg-emerald-100/90"
                      }`}
                    >
                      <div className="flex items-center space-x-1">
                        <div
                          className={`w-2 h-2 rounded-full ${
                            menu.is_sold_out ? "bg-gray-400" : "bg-emerald-400"
                          }`}
                        />
                        <span>{menu.is_sold_out ? "품절" : "판매중"}</span>
                      </div>
                    </button>
                  </div>
                  
                  <div className="p-4">
                    <h3 className="font-medium">{menu.name}</h3>
                    <p className="text-gray-600 mt-1">{formatKRW(menu.price)}</p>
                    <div className="flex flex-wrap gap-1 mt-2">
                      {menu.tags?.map((tag) => (
                        <span
                          key={tag.id}
                          className="px-2 py-0.5 border border-emerald-500 text-emerald-600 rounded-full text-xs bg-white"
                        >
                          {tag.name}
                        </span>
                      ))}
                    </div>
                    <div className="flex items-center space-x-2 mt-2">
                      <button
                        onClick={() => handleEditClick(menu)}
                        className="text-sm text-emerald-600 hover:text-emerald-700"
                      >
                        <Edit2 className="w-4 h-4 inline-block mr-1" />
                        수정
                      </button>
                      <button
                        onClick={() => handleDeleteMenu(menu.id)}
                        className="text-sm text-red-600 hover:text-red-700"
                      >
                        <Trash2 className="w-4 h-4 inline-block mr-1" />
                        삭제
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* 메뉴 추가 모달 */}
      {isAddingMenu && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h2 className="text-xl font-bold mb-4">새 메뉴 추가</h2>
            <form onSubmit={handleAddMenu} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  메뉴명
                </label>
                <input
                  type="text"
                  value={menuForm.name}
                  onChange={(e) =>
                    setMenuForm((prev) => ({ ...prev, name: e.target.value }))
                  }
                  className="input mt-1"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  가격
                </label>
                <input
                  type="text"
                  value={formatKRW(menuForm.price)}
                  onChange={(e) =>
                    setMenuForm((prev) => ({
                      ...prev,
                      price: formatNumberInput(e.target.value),
                    }))
                  }
                  className="input mt-1"
                  required
                  placeholder="0"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  설명
                </label>
                <textarea
                  value={menuForm.description}
                  onChange={(e) =>
                    setMenuForm((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }))
                  }
                  className="input mt-1"
                  rows={3}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  태그
                </label>
                <div className="flex flex-wrap gap-2">
                  {tags.map((tag) => (
                    <button
                      key={tag.id}
                      type="button"
                      onClick={() => handleTagSelect(tag.id)}
                      className={`px-3 py-1 rounded-full text-sm ${
                        menuForm.tagIds.includes(tag.id)
                          ? "bg-emerald-100 text-emerald-800"
                          : "bg-gray-100 text-gray-800 hover:bg-gray-200"
                      }`}
                    >
                      {tag.name}
                    </button>
                  ))}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  이미지
                </label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="mt-1"
                />
                {menuForm.imagePreview && (
                  <img
                    src={menuForm.imagePreview}
                    alt="미리보기"
                    className="mt-2 w-32 h-32 object-cover rounded"
                  />
                )}
              </div>

              <div className="flex justify-end space-x-4 mt-6">
                <button
                  type="button"
                  onClick={() => {
                    setIsAddingMenu(false);
                    setMenuForm({
                      name: "",
                      price: "",
                      description: "",
                      tagIds: [],
                      image: null,
                      imagePreview: null,
                    });
                  }}
                  className="btn btn-secondary"
                >
                  취소
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading ? "추가 중..." : "추가"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* 메뉴 수정 모달 */}
      {isEditingMenu && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h2 className="text-xl font-bold mb-4">메뉴 수정</h2>
            <form onSubmit={handleUpdateMenu} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  메뉴명
                </label>
                <input
                  type="text"
                  value={menuForm.name}
                  onChange={(e) =>
                    setMenuForm((prev) => ({ ...prev, name: e.target.value }))
                  }
                  className="input mt-1"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  가격
                </label>
                <input
                  type="text"
                  value={menuForm.price}
                  onChange={(e) =>
                    setMenuForm((prev) => ({
                      ...prev,
                      price: formatNumberInput(e.target.value),
                    }))
                  }
                  className="input mt-1"
                  required
                  placeholder="0"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  설명
                </label>
                <textarea
                  value={menuForm.description}
                  onChange={(e) =>
                    setMenuForm((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }))
                  }
                  className="input mt-1"
                  rows={3}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  태그
                </label>
                <div className="flex flex-wrap gap-2">
                  {tags.map((tag) => (
                    <button
                      key={tag.id}
                      type="button"
                      onClick={() => handleTagSelect(tag.id)}
                      className={`px-3 py-1 rounded-full text-sm ${
                        menuForm.tagIds.includes(tag.id)
                          ? "bg-emerald-100 text-emerald-800"
                          : "bg-gray-100 text-gray-800 hover:bg-gray-200"
                      }`}
                    >
                      {tag.name}
                    </button>
                  ))}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  이미지
                </label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="mt-1"
                />
                {menuForm.imagePreview && (
                  <img
                    src={menuForm.imagePreview}
                    alt="미리보기"
                    className="mt-2 w-32 h-32 object-cover rounded"
                  />
                )}
              </div>

              <div className="flex justify-end space-x-4 mt-6">
                <button
                  type="button"
                  onClick={() => {
                    setIsEditingMenu(false);
                    setSelectedMenu(null);
                    setMenuForm({
                      name: "",
                      price: "",
                      description: "",
                      tagIds: [],
                      image: null,
                      imagePreview: null,
                    });
                  }}
                  className="btn btn-secondary"
                >
                  취소
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading ? "수정 중..." : "수정"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default MenuManagement;
