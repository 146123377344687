// 숫자를 원화 형식으로 변환 (예: 1000 -> 1,000원)
export const formatKRW = (amount) => {
  return new Intl.NumberFormat('ko-KR').format(amount) + '원';
};

// 숫자만 입력받도록 처리
export const formatNumberInput = (value) => {
  return value.replace(/[^0-9]/g, '');
};

// 전화번호 포맷팅 (예: 01012341234 -> 010-1234-1234)
export const formatPhoneNumber = (phone) => {
  if (!phone) return '';
  
  const cleaned = phone.replace(/[^0-9]/g, '');
  const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
  
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }
  
  return phone;
};

// 영업시간 포맷팅 (예: "09:00" -> "오전 9:00")
export const formatTime = (time) => {
  if (!time) return '';
  
  return new Intl.DateTimeFormat('ko-KR', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  }).format(new Date(`2000-01-01T${time}`));
};

// 날짜 시간 포맷팅 (예: "2024-11-15 02:52:29" -> "오늘 오전 2시 52분 29초" 또는 "어제 오후 3시 30분 15초")
export const formatDateTime = (datetime) => {
  if (!datetime) return '';
  
  const now = new Date();
  const date = new Date(datetime);
  
  // 날짜 차이 계산
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const targetDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const diffDays = Math.floor((today - targetDate) / (1000 * 60 * 60 * 24));
  
  let dateStr;
  if (diffDays === 0) {
    dateStr = '오늘';
  } else if (diffDays === 1) {
    dateStr = '어제';
  } else {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    dateStr = `${month}월 ${day}일`;
  }

  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();
  const ampm = hour < 12 ? '오전' : '오후';
  const hour12 = hour % 12 || 12;

  return `${dateStr} ${ampm} ${hour12}시 ${minute}분 ${second}초`;
}; 