import { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import api from "../services/api";
import { TrendingUp, Users, ShoppingBag, AlertCircle } from "lucide-react";
import { formatKRW, formatDateTime } from "../utils/formatters";
import { format } from "date-fns";
import ko from "date-fns/locale/ko";

function Dashboard() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    todayOrders: 0,
    todayRevenue: 0,
    monthlyOrders: 0,
    monthlyRevenue: 0,
    recentOrders: [],
    pendingOrders: 0,
  });

  useEffect(() => {
    loadDashboardData();
  }, []);

  const loadDashboardData = async () => {
    try {
      const { data } = await api.get("store/dashboard/stats");
      setStats(data);
    } catch (error) {
      console.error("Failed to load dashboard data:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-600"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* 환영 메시지 */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h1 className="text-2xl font-bold text-gray-900">
          안녕하세요, {user?.name}님!
        </h1>
        <p className="mt-1 text-gray-600">
          {user?.store_name}의 대시보드입니다.
        </p>
      </div>

      {/* 통계 카드 */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {/* 오늘의 주문 */}
        <div className="bg-white p-6 rounded-lg shadow">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-600">오늘의 주문</p>
              <p className="mt-1 text-2xl font-semibold text-gray-900">
                {stats.todayOrders ?? 0}건
              </p>
            </div>
            <ShoppingBag className="h-8 w-8 text-emerald-600" />
          </div>
        </div>

        {/* 오늘의 매출 */}
        <div className="bg-white p-6 rounded-lg shadow">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-600">오늘의 매출</p>
              <p className="mt-1 text-2xl font-semibold text-gray-900">
                {formatKRW(stats.todayRevenue ?? 0)}
              </p>
            </div>
            <TrendingUp className="h-8 w-8 text-emerald-600" />
          </div>
        </div>

        {/* 이번 달 주문 */}
        <div className="bg-white p-6 rounded-lg shadow">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-600">이번 달 주문</p>
              <p className="mt-1 text-2xl font-semibold text-gray-900">
                {stats.monthlyOrders}건
              </p>
            </div>
            <Users className="h-8 w-8 text-emerald-600" />
          </div>
        </div>

        {/* 대기 중인 주문 */}
        <div className="bg-white p-6 rounded-lg shadow">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-600">
                대기 중인 주문
              </p>
              <p className="mt-1 text-2xl font-semibold text-gray-900">
                {stats.pendingOrders}건
              </p>
            </div>
            <AlertCircle className="h-8 w-8 text-emerald-600" />
          </div>
        </div>
      </div>

      {/* 최근 주문 목록 */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="p-6">
          <h2 className="text-lg font-medium text-gray-900">최근 주문</h2>
        </div>
        
        <div className="overflow-x-auto">
          <div className="hidden md:block"> {/* 데스크톱 테이블 뷰 */}
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">주문번호</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">금액</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">상태</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">시간</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {stats.recentOrders.map((order) => (
                  <tr key={order.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{order.order_number}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{formatKRW(order.total_amount)}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        order.status === "pending" ? "bg-yellow-100 text-yellow-800"
                        : order.status === "accepted" ? "bg-blue-100 text-blue-800"
                        : order.status === "completed" ? "bg-green-100 text-green-800"
                        : "bg-red-100 text-red-800"
                      }`}>
                        {order.status === "pending" ? "대기중"
                          : order.status === "accepted" ? "접수됨"
                          : order.status === "completed" ? "완료"
                          : "취소됨"}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatDateTime(order.created_at)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* 모바일 카드 뷰 */}
          <div className="md:hidden">
            {stats.recentOrders.map((order) => (
              <div key={order.id} className="p-4 border-b border-gray-200">
                <div className="flex justify-between items-start mb-2">
                  <span className="text-sm font-medium text-gray-900">{order.order_number}</span>
                  <span className={`px-2 text-xs leading-5 font-semibold rounded-full ${
                    order.status === "pending" ? "bg-yellow-100 text-yellow-800"
                    : order.status === "accepted" ? "bg-blue-100 text-blue-800"
                    : order.status === "completed" ? "bg-green-100 text-green-800"
                    : "bg-red-100 text-red-800"
                  }`}>
                    {order.status === "pending" ? "대기중"
                      : order.status === "accepted" ? "접수됨"
                      : order.status === "completed" ? "완료"
                      : "취소됨"}
                  </span>
                </div>
                <div className="text-sm text-gray-900 font-medium">{formatKRW(order.total_amount)}</div>
                <div className="text-xs text-gray-500 mt-1">{formatDateTime(order.created_at)}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
