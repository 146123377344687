import React from 'react';
import { AlertCircle } from "lucide-react";
import { Modal } from "./Modal";

export function QRResetModal({ isOpen, onClose, onConfirm }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="p-6">
        <div className="flex items-start">
          <div className="flex-shrink-0">
            <AlertCircle className="h-6 w-6 text-red-600" />
          </div>
          <div className="ml-3">
            <h3 className="text-lg font-medium text-gray-900">
              QR코드 재설정
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                QR코드를 재설정하면 기존 QR코드는 더 이상 사용할 수 없습니다.
                <br />
                정말 재설정하시겠습니까?
              </p>
            </div>
          </div>
        </div>

        <div className="mt-6 flex justify-end space-x-3">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onClose}
          >
            취소
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={onConfirm}
          >
            재설정
          </button>
        </div>
      </div>
    </Modal>
  );
} 