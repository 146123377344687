import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { Store } from "lucide-react";

function Register() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    passwordConfirm: "",
    storeName: "",
    phone: "",
    address: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { register } = useAuth();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    console.log("formData", formData);
    // 데이터 유효성 검사
    if (
      !formData.email ||
      !formData.password ||
      !formData.name ||
      !formData.phone ||
      !formData.storeName ||
      !formData.address
    ) {
      setError("모든 필수 항목을 입력해주세요!");
      return;
    }

    try {
      setLoading(true);
      // 비밀번호 확인은 제외하고 데이터 전송
      const { passwordConfirm, ...registerData } = formData;

      console.log("Register data:", registerData);

      await register(registerData);

      navigate("/login", {
        state: {
          message:
            "회원가입이 완료되었습니다. 이메일을 확인하여 계정을 인증해 주세요.",
        },
      });
    } catch (err) {
      setError(
        err.response?.data?.message || "회원가입 처리 중 오류가 발생했습니다."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-2xl mx-auto">
        <div className="text-center">
          <Store className="mx-auto h-12 w-12 text-brand-600" />
          <h2 className="mt-6 text-3xl font-bold text-gray-900">
            스토어 관리자 회원가입
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            이미 계정이 있으신가요?{" "}
            <Link
              to="/login"
              className="font-medium text-brand-600 hover:text-brand-500"
            >
              로그인하기
            </Link>
          </p>
        </div>

        <form onSubmit={handleSubmit} className="mt-8 space-y-6">
          {error && (
            <div className="bg-red-50 text-red-500 p-4 rounded-lg text-sm">
              {error}
            </div>
          )}

          <div className="bg-white shadow-sm rounded-lg p-6 space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* 개인 정보 */}
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  이름
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  required
                  className="input mt-1"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  이메일
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  required
                  className="input mt-1"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  비밀번호
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  required
                  className="input mt-1"
                  value={formData.password}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label
                  htmlFor="passwordConfirm"
                  className="block text-sm font-medium text-gray-700"
                >
                  비밀번호 확인
                </label>
                <input
                  type="password"
                  name="passwordConfirm"
                  id="passwordConfirm"
                  required
                  className="input mt-1"
                  value={formData.passwordConfirm}
                  onChange={handleChange}
                />
              </div>

              {/* 매장 정보 */}
              <div className="md:col-span-2">
                <label
                  htmlFor="storeName"
                  className="block text-sm font-medium text-gray-700"
                >
                  매장명
                </label>
                <input
                  type="text"
                  name="storeName"
                  id="storeName"
                  required
                  className="input mt-1"
                  value={formData.storeName}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  연락처
                </label>
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  required
                  className="input mt-1"
                  placeholder="010-0000-0000"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>

              <div className="md:col-span-2">
                <label
                  htmlFor="address"
                  className="block text-sm font-medium text-gray-700"
                >
                  매장 주소
                </label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  required
                  className="input mt-1"
                  value={formData.address}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="flex items-center justify-end space-x-4">
            <Link to="/login" className="btn btn-secondary">
              취소
            </Link>
            <button
              type="submit"
              disabled={loading}
              className="btn btn-primary"
            >
              {loading ? "처리 중..." : "회원가입"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Register;
