import { createContext, useContext, useState, useEffect } from 'react';
import api from '../services/api';
import { useAuth } from '../contexts/AuthContext';

const StoreContext = createContext();

export function StoreProvider({ children }) {
  const [stores, setStores] = useState([]);
  const [currentStore, setCurrentStore] = useState(null);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    console.log("user", user);
    if (user?.role === 'store_owner') {
      loadUserStores();
    }
  }, [user]);

  const loadUserStores = async () => {
    try {
      const { data } = await api.get('/stores/my-stores');
      setStores(data);
      
  const savedStoreId = localStorage.getItem('currentStoreId');
      const initialStore = savedStoreId 
        ? data.find(store => store.id === parseInt(savedStoreId))
        : data[0];
        
      if (initialStore) {
        setCurrentStore(initialStore);
        localStorage.setItem('currentStoreId', initialStore.id);
      }
    } catch (error) {
      console.error('스토어 정보 로드 실패:', error);
    } finally {
      setLoading(false);
    }
  };

  const switchStore = (storeId) => {
    const store = stores.find(s => s.id === parseInt(storeId));
    if (store) {
      setCurrentStore(store);
      localStorage.setItem('currentStoreId', store.id);
    }
  };

  return (
    <StoreContext.Provider value={{ 
      stores, 
      currentStore, 
      switchStore, 
      loading 
    }}>
      {children}
    </StoreContext.Provider>
  );
}

export const useStore = () => useContext(StoreContext); 