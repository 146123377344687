import { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Store } from 'lucide-react';
import api from '../services/api';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { login, user, error: authError } = useAuth();
  const [error, setError] = useState('');
  const [message, setMessage] = useState(location.state?.message || '');
  const [showResendButton, setShowResendButton] = useState(false);

  // 이미 로그인된 경우 대시보드로 리다이렉트
  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setShowResendButton(false);

    try {
      setLoading(true);
      await login(email, password);
      navigate('/');
    } catch (err) {
      const errorMessage = err.response?.data?.message || '로그인에 실패했습니다.';
      setError(errorMessage);
      
      // 이메일 인증이 필요한 경우
      if (err.response?.data?.needsVerification) {
        setShowResendButton(true);
      }
    } finally {
      setLoading(false);
    }
  };

  // 인증 메일 재전송 함수 추가
  const handleResendVerification = async () => {
    try {
      setLoading(true);
      await api.post('/store/auth/resend-verification', { email });
      setMessage('인증 메일이 재전송되었습니다. 이메일을 확인해주세요.');
      setShowResendButton(false);
    } catch (err) {
      setError(err.response?.data?.message || '인증 메일 재전송에 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div className="text-center">
          <Store className="mx-auto h-12 w-12 text-emerald-600" />
          <h2 className="mt-6 text-3xl font-bold text-gray-900">
            스토어 관리자 로그인
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            또는{' '}
            <Link to="/register" className="font-medium text-emerald-600 hover:text-emerald-500">
              새로운 스토어를 등록하세요
            </Link>
          </p>
        </div>

   

        {/* 에러 메시지 */}
        {error && (
          <div className="bg-red-50 text-red-600 p-4 rounded-md text-sm">
            <p>{error}</p>
            {showResendButton && (
              <button
                onClick={handleResendVerification}
                className="mt-2 text-sm font-medium text-red-600 hover:text-red-500"
                disabled={loading}
              >
                인증 메일 다시 받기
              </button>
            )}
          </div>
        )}

        {message && (
          <div className="bg-emerald-50 text-emerald-800 p-4 rounded-md text-sm">
            {message}
          </div>
        )}

        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                이메일
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="input mt-1"
                placeholder="example@email.com"
                disabled={loading}
              />
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                비밀번호
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="input mt-1"
                placeholder="••••••••"
                disabled={loading}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={loading}
              className={`btn btn-primary w-full ${loading ? 'opacity-75 cursor-not-allowed' : ''}`}
            >
              {loading ? (
                <span className="flex items-center justify-center">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  로그인 중...
                </span>
              ) : (
                '로그인'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;