import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Store } from "lucide-react";
import api from "../services/api";

function VerifyEmail() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState("verifying"); // verifying, success, error
  const [message, setMessage] = useState("");

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const token = searchParams.get("token");
        if (!token) {
          setStatus("error");
          setMessage("유효하지 않은 인증 링크입니다.");
          return;
        }

        const response = await api.get(
          `/store/auth/verify-email?token=${token}`
        );
        setStatus("success");
        setMessage(response.data.message);

        // 3초 후 로그인 페이지로 이동
        setTimeout(() => {
          navigate("/login", {
            state: {
              message: "이메일 인증이 완료되었습니다. 로그인해 주세요.",
            },
          });
        }, 3000);
      } catch (error) {
        setStatus("error");
        setMessage(
          error.response?.data?.message || "이메일 인증에 실패했습니다."
        );
      }
    };

    verifyEmail();
  }, [searchParams, navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 text-center">
        <Store className="mx-auto h-12 w-12 text-emerald-600" />
        <h2 className="text-3xl font-bold text-gray-900">이메일 인증</h2>

        {status === "verifying" && (
          <div className="mt-4">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-600 mx-auto"></div>
            <p className="mt-4 text-gray-600">
              이메일 인증을 처리하고 있습니다...
            </p>
          </div>
        )}

        {status === "success" && (
          <div className="mt-4 bg-emerald-50 text-emerald-800 p-4 rounded-md">
            <p>{message}</p>
            <p className="mt-2 text-sm">
              잠시 후 로그인 페이지로 이동합니다...
            </p>
          </div>
        )}

        {status === "error" && (
          <div className="mt-4 bg-red-50 text-red-600 p-4 rounded-md">
            <p>{message}</p>
            <button
              onClick={() => navigate("/login")}
              className="mt-4 btn btn-primary"
            >
              로그인 페이지로 이동
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default VerifyEmail;
