import { X } from "lucide-react";

export function Modal({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-full items-end sm:items-center justify-center p-0 sm:p-4 text-center">
        <div 
          className="fixed inset-0 bg-black bg-opacity-25 transition-opacity" 
          onClick={onClose}
          aria-hidden="true"
        />
        
        <div className="relative inline-block w-full sm:max-w-lg p-4 sm:p-6 text-left bg-white shadow-xl transform transition-all sm:rounded-lg">
          <div className="absolute right-4 top-4">
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-emerald-500"
            >
              <span className="sr-only">닫기</span>
              <X className="h-5 w-5" />
            </button>
          </div>
          
          <div className="mt-2">{children}</div>
        </div>
      </div>
    </div>
  );
}
