import axios from "axios";

// axios 기본 설정
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "/api",
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true, // CORS 인증 설정
});

// 요청 인터셉터 - 토큰 추가
api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// 응답 인터셉터 - 에러 처리
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

// 인증 관련 API
export const authAPI = {
  // 회원가입
  register: (data) => api.post("/store/auth/register", data),

  // 로그인
  login: (email, password) =>
    api.post("/store/auth/login", { email, password }),

  // 현재 사용자 정보 조회
tMe: () => api.get("/store/auth/me"),
};

export default api;
