import { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import { format } from "date-fns";
import { ko } from "date-fns/locale";
import { useToast } from "../hooks/useToast";
import { io } from "socket.io-client";
import api from "../services/api";
import { formatKRW } from "../utils/formatters";
import { Check, X, Edit2, Trash2 } from "lucide-react";

function OrderManagement() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const { showToast } = useToast();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showHistory, setShowHistory] = useState(false);

  useEffect(() => {
    loadOrders();
    
    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      path: "/socket.io",
      transports: ["websocket", "polling"],
      secure: true,
      rejectUnauthorized: false,
    });

    socket.on("new_order", (newOrder) => {
      setOrders((prev) => [{
        ...newOrder,
        isNew: true,
        items: newOrder.items || [],
        status: 'pending',
        created_at: new Date().toISOString()
      }, ...prev]);

      // 3초 후에 isNew 플래그 제거
      setTimeout(() => {
        setOrders(prev => 
          prev.map(order => 
            order.id === newOrder.id ? { ...order, isNew: false } : order
          )
        );
      }, 3000);
    });

    socket.on("order_updated", ({ orderId, status }) => {
      setOrders(prev =>
        prev.map(order =>
          order.id === orderId ? { ...order, status } : order
        )
      );
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const loadOrders = async () => {
    try {
      const { data } = await api.get("/orders");
      setOrders(data);
    } catch (error) {
      showToast("주문 목록을 불러오는데 실패했습니다.", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleOrderAction = async (orderId, action) => {
    try {
      await api.patch(`/orders/${orderId}/${action}`);
      await loadOrders();
      showToast(
        action === "complete"
          ? "주문이 완료되었습니다."
          : "주문이 취소되었습니다.",
        "success"
      );
    } catch (error) {
      console.error("Order action error:", error);
      showToast("주문 상태 변경에 실패했습니다.", "error");
    }
  };

  const handleComplete = (orderId) => {
    handleOrderAction(orderId, "complete");
  };

  const handleCancel = (orderId) => {
    handleOrderAction(orderId, "cancel");
  };

  const SwipeHint = () => (
    <div className="flex items-center justify-center space-x-4 text-sm text-gray-500 mb-4">
      <div className="flex items-center">
        <Check className="w-4 h-4 mr-1 text-emerald-500" />
        <span> 완료</span>
      </div>
      <div className="flex items-center">
        <X className="w-4 h-4 mr-1 text-red-500" />
        <span> 취소</span>
      </div>
    </div>
  );

  const OrderCard = ({ order, onComplete, onCancel }) => {
    const isMobile = window.innerWidth <= 768;

    return (
      <div className="relative">
        <div className={`
          bg-white rounded-lg shadow-md p-4 mb-4
          relative overflow-hidden
          hover:shadow-lg transition-all duration-200
          ${order.isNew ? 'animate-pulse shadow-emerald-200 ring-2 ring-emerald-500' : ''}
        `}>
          {/* 영수증 스타일 헤더 */}
          <div className="border-b-2 border-dashed border-gray-200 pb-4 mb-4">
            <div className="flex justify-between items-start">
              <div>
                <div className="font-bold text-2xl mb-1">주문 #{order.order_number}</div>
                <div className="text-sm text-gray-500">
                  {format(new Date(order.created_at), "yyyy.MM.dd HH:mm", { locale: ko })}
                </div>
              </div>
              {/* 모바일 액션 버튼 */}
              {isMobile && (
                <div className="flex space-x-2">
                  <button
                    onClick={() => onComplete(order.id)}
                    className="p-2 rounded-full bg-emerald-100 text-emerald-600 active:bg-emerald-200"
                  >
                    <Check className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => onCancel(order.id)}
                    className="p-2 rounded-full bg-red-100 text-red-600 active:bg-red-200"
                  >
                    <X className="w-5 h-5" />
                  </button>
                </div>
              )}
            </div>
            <div className="mt-2">
              <span className={`inline-block px-3 py-1 rounded-full text-sm ${
                order.status === 'pending' ? 'bg-yellow-100 text-yellow-800' : 
                order.status === 'completed' ? 'bg-emerald-100 text-emerald-800' : 
                'bg-red-100 text-red-800'
              }`}>
                {order.status === 'pending' ? '대기중' : 
                 order.status === 'completed' ? '완료' : '취소됨'}
              </span>
            </div>
          </div>

          {/* 주문 아이템 목록 */}
          <div className="space-y-3 mb-4">
            {order.items.map((item) => (
              <div key={item.id} className="flex justify-between items-center text-sm">
                <div className="flex-1">
                  <div className="font-medium">{item.name}</div>
                  <div className="text-gray-500 text-xs">
                    {formatKRW(item.price)} × {item.quantity}
                  </div>
                </div>
                <div className="font-medium">
                  {formatKRW(item.price * item.quantity)}
                </div>
              </div>
            ))}
          </div>

          {/* 총액 */}
          <div className="border-t-2 border-dashed border-gray-200 pt-4">
            <div className="flex justify-between items-center">
              <span className="text-gray-600">총 주문금액</span>
              <span className="text-lg font-bold">{formatKRW(order.total_amount)}</span>
            </div>
          </div>

          {/* 데스크톱 버튼 */}
          {!isMobile && (
            <div className="flex flex-col space-y-2 mt-4">
              <button
                type="button"
                onClick={() => onComplete(order.id)}
                className="flex items-center justify-center w-full px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors"
              >
                <Check className="w-4 h-4 mr-2" />
                완료
              </button>
              <button
                type="button"
                onClick={() => onCancel(order.id)}
                className="flex items-center justify-center w-full px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
              >
                <X className="w-4 h-4 mr-2" />
                취소
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-600" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">주문 관리</h1>
        <button
          onClick={() => setShowHistory(!showHistory)}
          className="btn btn-secondary"
        >
          {showHistory ? "진행중인 주문" : "주문 내역"}
        </button>
      </div>

      {/* 모바일에서만 스와이프 힌트 표시 */}
      {window.innerWidth <= 768 && <SwipeHint />}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {orders
          .filter((order) =>
            showHistory
              ? order.status !== "pending"
              : order.status === "pending"
          )
          .map((order) => (
            <OrderCard key={order.id} order={order} onComplete={handleComplete} onCancel={handleCancel} />
          ))}
      </div>
    </div>
  );
}

export default OrderManagement;
