import { useState, useEffect } from "react";
import {
  MapPin,
  Phone,
  Clock,
  Tag,
  AlertCircle,
  ImageIcon,
} from "lucide-react";
import api from "../services/api";
import { formatPhoneNumber } from "../utils/formatters";
import { useToast } from "../hooks/useToast";
import { QRResetModal } from "../components/QRResetModal";
import { Switch } from "../components/Switch";

function StoreManagement() {
  const [store, setStore] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [showQRModal, setShowQRModal] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const { showToast } = useToast();

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    address: "",
    description: "",
    image: null,
    imagePreview: null,
    operation_hours: {
      monday: { open: "09:00", close: "22:00", isOpen: true },
      tuesday: { open: "09:00", close: "22:00", isOpen: true },
      wednesday: { open: "09:00", close: "22:00", isOpen: true },
      thursday: { open: "09:00", close: "22:00", isOpen: true },
      friday: { open: "09:00", close: "22:00", isOpen: true },
      saturday: { open: "09:00", close: "22:00", isOpen: true },
      sunday: { open: "09:00", close: "22:00", isOpen: true },
    },
  });

  useEffect(() => {
    loadStoreInfo();
  }, []);

  const loadStoreInfo = async () => {
    try {
      const { data } = await api.get("/store/info");
      if (!data) {
        setError("스토어 정보가 없습니다.");
        return;
      }

      setStore(data);
      setFormData({
        name: data.name || "",
        phone: data.phone || "",
        address: data.address || "",
        description: data.description || "",
        image: data.image || null,
        imagePreview: data.imagePreview || null,
        operation_hours: data.operation_hours || {
          monday: { open: "09:00", close: "22:00", isOpen: true },
          tuesday: { open: "09:00", close: "22:00", isOpen: true },
          wednesday: { open: "09:00", close: "22:00", isOpen: true },
          thursday: { open: "09:00", close: "22:00", isOpen: true },
          friday: { open: "09:00", close: "22:00", isOpen: true },
          saturday: { open: "09:00", close: "22:00", isOpen: true },
          sunday: { open: "09:00", close: "22:00", isOpen: true },
        },
      });
    } catch (error) {
      setError("스토어 정보를 불러오는데 실패했습니다.");
      console.error("Failed to load store info:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name.trim());
      formDataToSend.append("phone", formData.phone.trim());
      formDataToSend.append("address", formData.address.trim());
      formDataToSend.append("description", formData.description?.trim() || "");
      formDataToSend.append(
        "operation_hours",
        JSON.stringify(formData.operation_hours)
      );

      if (formData.image) {
        formDataToSend.append("image", formData.image);
      }

      const { data } = await api.put("/store/info", formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (formData.imagePreview) {
        URL.revokeObjectURL(formData.imagePreview);
      }

      setStore(data);
      setIsEditing(false);
      showToast("스토어 정보가 수정되었습니다.");
    } catch (error) {
      showToast("스토어 정보 수정에 실패했습니다.", "error");
      console.error("Failed to update store info:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleStatus = async () => {
    try {
      const { data } = await api.patch("/store/toggle-status");
      setStore((prev) => ({ ...prev, status: data.status }));
      showToast(
        `영업 상태가 ${
          data.status === "open" ? "영업중" : "영업종료"
        }으로 변경되었습니다.`
      );
    } catch (error) {
      showToast("영업 상태 변경에 실패했습니다.", "error");
    }
  };

  const handleOperationHoursChange = (day, field, value) => {
    setFormData((prev) => {
      const newOperationHours = { ...prev.operation_hours };
      
      newOperationHours[day] = {
        ...newOperationHours[day],
        [field]: value
      };

      return {
        ...prev,
        operation_hours: newOperationHours
      };
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prev) => ({
        ...prev,
        image: file,
        imagePreview: URL.createObjectURL(file),
      }));
    }
  };

  const handleResetQR = async () => {
    try {
      await api.post("/store/reset-qr");
      setShowQRModal(false);
      showToast("QR코드가 재설정되었습니다.");
      await loadStoreInfo();
    } catch (error) {
      showToast("QR코드 재설정에 실패했습니다.", "error");
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-600"></div>
      </div>
    );
  }

  const DAYS = {
    monday: "월요일",
    tuesday: "화요일",
    wednesday: "수요일",
    thursday: "목요일",
    friday: "금요일",
    saturday: "토요일",
    sunday: "일요일",
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-5xl">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6 space-y-4 md:space-y-0">
        <h1 className="text-2xl font-bold text-gray-900">스토어 관리</h1>
        {store && (
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <Switch
                checked={store.status === "open"}
                onChange={handleToggleStatus}
                disabled={loading}
              />
              <span className="text-sm font-medium text-gray-700">
                {store.status === "open" ? "영업중" : "영업종료"}
              </span>
            </div>
            {!isEditing && (
              <button
                onClick={() => setIsEditing(true)}
                className="btn btn-primary"
              >
                정보 수정
              </button>
            )}
          </div>
        )}
      </div>

      {error && (
        <div className="bg-red-50 text-red-600 p-4 rounded-md mb-6 animate-fade-in">
          <div className="flex items-center">
            <AlertCircle className="w-5 h-5 mr-2 flex-shrink-0" />
            <span className="text-sm">{error}</span>
          </div>
        </div>
      )}

      <div className="bg-white rounded-lg shadow-lg">
        <form onSubmit={handleSubmit} className="p-6 space-y-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                스토어 이름
              </label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, name: e.target.value }))
                }
                disabled={!isEditing}
                className="input w-full transition-colors"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                전화번호
              </label>
              <input
                type="tel"
                value={formData.phone}
                onChange={(e) => {
                  const formatted = formatPhoneNumber(e.target.value);
                  setFormData((prev) => ({ ...prev, phone: formatted }));
                }}
                disabled={!isEditing}
                className="input w-full transition-colors"
                required
                placeholder="010-0000-0000"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              주소
            </label>
            <input
              type="text"
              value={formData.address}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, address: e.target.value }))
              }
              disabled={!isEditing}
              className="input w-full transition-colors"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              설명
            </label>
            <textarea
              value={formData.description}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
              disabled={!isEditing}
              className="input w-full transition-colors"
              rows={3}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-4">
              영업 시간
            </label>
            <div className="space-y-3">
              {Object.entries(DAYS).map(([day, label]) => (
                <div
                  key={day}
                  className="flex flex-col p-4 bg-white rounded-lg border border-gray-200 hover:border-gray-300 transition-colors"
                >
                  <div className="flex items-center justify-between mb-3">
                    <span className="font-medium text-gray-900">
                      {label}
                    </span>
                    <Switch
                      checked={formData.operation_hours[day].isOpen}
                      onChange={(checked) => {
                        handleOperationHoursChange(day, "isOpen", checked);
                      }}
                      disabled={!isEditing}
                    />
                  </div>

                  <div className="flex flex-col sm:flex-row gap-3">
                    <div className="flex-1">
                      <input
                        type="time"
                        value={formData.operation_hours[day].open}
                        onChange={(e) =>
                          handleOperationHoursChange(day, "open", e.target.value)
                        }
                        disabled={!isEditing || !formData.operation_hours[day].isOpen}
                        className={`w-full px-3 py-2 rounded-lg border ${
                          formData.operation_hours[day].isOpen
                            ? "border-gray-300 bg-white"
                            : "border-gray-200 bg-gray-50"
                        } focus:ring-2 focus:ring-emerald-500 focus:border-transparent transition-colors`}
                      />
                    </div>
                    <span className="text-gray-400 self-center">~</span>
                    <div className="flex-1">
                      <input
                        type="time"
                        value={formData.operation_hours[day].close}
                        onChange={(e) =>
                          handleOperationHoursChange(day, "close", e.target.value)
                        }
                        disabled={!isEditing || !formData.operation_hours[day].isOpen}
                        className={`w-full px-3 py-2 rounded-lg border ${
                          formData.operation_hours[day].isOpen
                            ? "border-gray-300 bg-white"
                            : "border-gray-200 bg-gray-50"
                        } focus:ring-2 focus:ring-emerald-500 focus:border-transparent transition-colors`}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                스토어 이미지
              </label>
              <div className="mt-1 flex items-center space-x-4">
                <div className="w-32 h-32 relative rounded-lg overflow-hidden bg-gray-100">
                  {formData.imagePreview || store?.image_url ? (
                    <img
                      src={
                        formData.imagePreview ||
                        `${process.env.REACT_APP_IMAGE_URL}/${store.image_url}`
                      }
                      alt="Store"
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="flex items-center justify-center w-full h-full text-gray-400">
                      <ImageIcon className="w-8 h-8" />
                    </div>
                  )}
                </div>
                {isEditing && (
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="hidden"
                    id="store-image"
                  />
                )}
                {isEditing && (
                  <label
                    htmlFor="store-image"
                    className="btn btn-secondary cursor-pointer"
                  >
                    이미지 변경
                  </label>
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                QR 코드
              </label>
              <div className="mt-1 flex items-center space-x-4">
                <div className="w-32 h-32 relative rounded-lg overflow-hidden bg-gray-100">
                  {store?.qr_code_url && (
                    <img
                      src={`${process.env.REACT_APP_IMAGE_URL}/${store.qr_code_url}`}
                      alt="QR Code"
                      className="w-full h-full object-contain"
                    />
                  )}
                </div>
                <button
                  type="button"
                  onClick={() => setShowQRModal(true)}
                  className="btn btn-secondary"
                >
                  QR코드 재설정
                </button>
              </div>
            </div>
          </div>

          {isEditing && (
            <div className="flex justify-end space-x-4 pt-4 border-t">
              <button
                type="button"
                onClick={() => {
                  setIsEditing(false);
                  setFormData({
                    ...store,
                    name: store.name,
                    phone: store.phone,
                    address: store.address,
                    description: store.description || "",
                    operation_hours: JSON.parse(JSON.stringify(store.operation_hours)),
                    image: null,
                    imagePreview: null
                  });
                }}
                className="btn btn-secondary w-24"
              >
                취소
              </button>
              <button
                type="submit"
                className="btn btn-primary w-24"
                disabled={loading}
              >
                {loading ? "저장 중..." : "저장"}
              </button>
            </div>
          )}
        </form>
      </div>

      <QRResetModal
        isOpen={showQRModal}
        onClose={() => setShowQRModal(false)}
        onConfirm={handleResetQR}
      />
    </div>
  );
}

export default StoreManagement;
